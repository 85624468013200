import React from "react";
import './contactButton.css'

export default function Contactbutton () {
    return (
        <div className="button-contact-all">
            <a href="https://wa.link/rdww5u" rel="noreferrer" target="_blank" className="contact-all-text">
                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/479px-WhatsApp.svg.png" alt="" className="logo"/>
            </a>
        </div>
    )
}