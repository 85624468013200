import React from "react";
import './footer.css'

export default function Footer() {
    return (
        <footer className="cnt_footer">
            <ul className="list">
                <li className="item">© INVERSIONES DUBÁI 2024. TODOS LOS DERECHOS RESERVADOS</li>
                <li className="item">CONTÁCTANOS</li>
                <li className="item">TÉRMINOS Y CONDICIONES</li>
                <li className="item">POLÍTICA DE PRIVACIDAD</li>
                <li className="item">DESARROLLADO POR <a className="color underline" target="_blank" rel="noreferrer" href="https://portfolio-virid-mu-25.vercel.app/">FABIAN TINJACA</a></li>
            </ul>
        </footer>
    )
}