import React, {useState, useEffect, useRef} from 'react';
import {useSpring, animated} from '@react-spring/web';
import {Link} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';
import CountUp from 'react-countup';
import Slider from 'react-slick';
import './Home.css';
import YouTube from 'react-youtube';

import quotation from '../../assets/svg/quotation.svg'
import quotationw from '../../assets/svg/quotation-white.svg'
import CalendlyPopupButton from '../calendly/calendly';
import on from '../../assets/images/ON.png';


const YouTubeVideoPlayer = ({
                                videoId,
                                title = "Video"
                            }) => {
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);

    const loadVideo = () => {
        setIsVideoLoaded(true);
    };

    return (
        <div className="youtube-video-container">
            {!isVideoLoaded ? (
                <div
                    onClick={loadVideo}
                    className="video-placeholder"
                    style={{
                        backgroundImage: `url('https://img.youtube.com/vi/${videoId}/maxresdefault.jpg')`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                    }}
                >
                    <div className="play-overlay">
                        <button
                            className="play-button"
                            aria-label="Reproducir video"
                        >
                            ▶ Reproducir
                        </button>
                    </div>
                </div>
            ) : (
                <div className="youtube-player-wrapper">
                    <YouTube
                        videoId={videoId}
                        className="youtube-player"
                        opts={{
                            width: '100%',
                            height: '100%',
                            playerVars: {
                                autoplay: 1,
                                modestbranding: 1,
                                controls: 1
                            }
                        }}
                        title={title}
                    />
                </div>
            )}
        </div>
    );
};

const WordCarousel = ({words, index}) => {
    const props = useSpring({
        opacity: 1,
        from: {opacity: 0},
        reset: true,
        reverse: false,
        config: {duration: 100}, // Duración de la animación
    });

    return (
        <div className="carousel">
            {words.map((word, i) => (
                <animated.div
                    key={i}
                    style={{
                        ...props,
                        opacity: index === i ? 1 : 0,
                    }}
                    className="word-props"
                >
                    {word}
                </animated.div>
            ))}
        </div>
    );
};

export default function Home() {

    const words = ['ASESORÍAS', 'COACHINGS', 'ESPECIALISTAS'];

    const [index, setIndex] = useState(0);

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkIsMobile = () => {
            setIsMobile(window.innerWidth <= 768); // Considera móvil si el ancho es <= 768px
        };

        checkIsMobile();

        window.addEventListener('resize', checkIsMobile);

        return () => {
            window.removeEventListener('resize', checkIsMobile);
        };
    }, []);

    // Actualizar el índice de la palabra actual cada 3 segundos
    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((prev) => (prev + 1) % words.length);
        }, 1800); // Cambia cada 3 segundos

        return () => clearInterval(interval);
    }, [words.length]);


    const data = [
        {
            image: on,
            title: 'TRANSFORMA TU VIDA ASÍ',
            text: 'Abr 14, 2024',
            link: 'https://www.youtube.com/watch?v=ahXZ9P0xtWc'
        },
        {
            image: on,
            title: 'RESTAURANTERO A CONSULTOR',
            text: 'Abr 1, 2024',
            link: 'https://www.youtube.com/watch?v=gxS5cGgXf48&t=6s'
        },
        {
            image: on,
            title: 'EL INTERNET ME SALVÓ LA VIDA',
            text: 'Mar 9, 2024',
            link: 'https://www.youtube.com/watch?v=V1BrnnP4Fow&list=PLkY-1Giup84EJ9eRYazIkMFrNJG4Er7N8&index=3'
        },
        {
            image: on,
            title: 'EL NEGOCIO DEL FUTURO',
            text: 'Jun 7, 2024',
            link: 'https://www.youtube.com/watch?v=lkDcT6adnDk'
        },
    ];

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        adaptiveHeight: true
    };

    return (
        <main>
            <Helmet>
                <title>Invierte en Dubái con Diego Carranza | Inversiones Dubai</title>
                <meta name="description"
                      content="Experto en inversiones en Dubái. Asesoramos a empresas y particulares de Europa, Latinoamérica y Norte America para invertir de forma segura en el mercado inmobiliario, criptomonedas y apertura de empresas en Dubái. ¡Agenda tu asesoría gratuita!"/>

                <meta property="og:title" content="Invierte en Dubái con Diego Carranza | Inversiones Seguras"/>
                <meta property="og:description"
                      content="Experto asesor en inversiones en Dubái. Especialistas en bienes raíces, criptomonedas y Golden Visa para España, Latinoamérica y USA. +1500 clientes asesorados en más de 35 países."/>
                <meta property="og:image"
                      content="https://res.cloudinary.com/dwyyydaq9/image/upload/v1733958055/logo_fyehyx.png"/>
                <meta property="og:url" content="https://inversionesdubai.com"/>
                <meta property="og:type" content="website"/>
                <meta property="og:site_name" content="Inversiones Dubai"/>
                <meta property="og:locale" content="es_ES"/>
                <meta property="og:locale:alternate" content="en_US"/>

                {/* Twitter Cards */}
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title" content="Invierte en Dubái - Asesoría Profesional en Inversiones"/>
                <meta name="twitter:description"
                      content="Experto en inversiones en Dubái. Asesoramiento profesional para invertir en bienes raíces, criptomonedas y obtener la Golden Visa. Agenda tu consulta gratuita."/>
                <meta name="twitter:image"
                      content="https://res.cloudinary.com/dwyyydaq9/image/upload/v1733958055/logo_fyehyx.png"/>

                {/* Metadatos Adicionales */}
                <meta name="keywords"
                      content="inversiones dubai, bienes raices dubai, golden visa emiratos, criptomonedas dubai, invertir en dubai, asesor inversiones dubai, residencia dubai, emigrar a dubai"/>
                <meta name="author" content="Diego Carranza"/>
                <meta name="robots"
                      content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"/>
                <meta name="googlebot" content="index, follow"/>

                {/* Idiomas Alternativos */}
                <link rel="alternate" hrefLang="es" href="https://inversionesdubai.com"/>
                <link rel="alternate" hrefLang="x-default" href="https://inversionesdubai.com"/>

                {/* Metadatos de Geolocalización */}
                <meta name="geo.region" content="AE"/>
                <meta name="geo.placename" content="Dubai"/>

                {/* Canonical URL */}
                <link rel="canonical" href="https://inversionesdubai.com"/>

                {/* Meta Theme Color */}
                <meta name="theme-color" content="#D2A164"/>

                {/* Meta Viewport */}
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
            </Helmet>

            <section className="cnt_welcome-background">
                {!isMobile && (
                    <video autoPlay muted loop className="background-video">
                        <source
                            src="https://res.cloudinary.com/dwyyydaq9/video/upload/v1731796729/Video_Negro_2_nsnueb.mp4"
                            type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video>
                )}
                <p className="welcome-init-title">
                    Globaliza tu negocio en Dubái. Ayudamos a expertos y empresas a expandir su mercado en Dubái.
                </p>
                <h1 className="welcome-title">
                    INVIERTE EN DUBÁI <i>CON <span className='color'>DIEGO CARRANZA</span></i>
                </h1>
                <div className="carousel-container">
                    <p className="fixed-text">LOS RESULTADOS REALES SE OBTIENEN <span className='weight color'>POR MEDIO DE</span>
                    </p>
                    <WordCarousel words={words} index={index}/>
                </div>
                <p className="additional-info">
                    <strong>¡No esperes más para abrir tu empresa o comprar tu Casa en Dubai! </strong> <br/>
                    Protege tu dinero de la inflación. Agenda tu primer cita <u className='color weight'>GRATIS</u> y te ayudaremos a invertir  <br/>  de
                    forma
                    segura en Dubái <i className='color'>SIN PAGAR IMPUESTOS</i>
                </p>
                <div className='cnt_arrows'>
                    <img src="https://danlok.com/wp-content/uploads/2022/08/Arrows-Type-1.png" alt=''
                         className='yellow-arrows'/>
                    <CalendlyPopupButton/>
                    <img src="https://danlok.com/wp-content/uploads/2022/08/Arrows-Type-1.png" alt=''
                         className='yellow-arrows scarrow'/>
                </div>
                <div>
                    <p className='ft'>Featured In</p>
                    <img src="https://danlok.com/wp-content/uploads/2022/08/Dan-Lok-Featured-In-Logos.png" alt='logos'
                         className='logos'/>
                </div>
            </section>
            <section className='cnt_counter'>
                <div className="cnt_counter-info">
                    <div className="cnt_counter-info-numbers-all">
                        <div className='counter'>
                            <CountUp
                                start={0}
                                end={1500}
                                duration={2} // Duración de la animación en segundos
                                separator=","
                                suffix="+"  // Opcional: sufijo
                                className='counter-number'
                            />
                            <p className='counter-text'>ASESORADOS</p>
                        </div>
                        <div className='counter'>
                            <CountUp
                                start={0}
                                end={35}
                                duration={2} // Duración de la animación en segundos
                                separator=","
                                suffix="+"  // Opcional: sufijo
                                className='counter-number'
                            />
                            <p className='counter-text'>PAÍSES</p>
                        </div>
                        <div className='counter'>
                            <CountUp
                                start={0}
                                end={25}
                                duration={2} // Duración de la animación en segundos
                                separator=","
                                prefix="$"
                                suffix="M+"
                                className='counter-number'
                            />
                            <p className='counter-text'>GENERADO</p>
                        </div>
                        <div className='counter'>
                            <CountUp
                                start={0}
                                end={100}
                                duration={2} // Duración de la animación en segundos
                                separator=","
                                suffix="M+"  // Opcional: sufijo
                                className='counter-number'
                            />
                            <p className='counter-text'>VISITAS</p>
                        </div>
                    </div>
                    <p className='counter-text-info'>Invierte de forma segura en Dubái con nuestro asesoramiento
                        experto. Te guiamos en inversiones inmobiliarias, la Golden Visa y la apertura de empresas,
                        brindando datos confiables y herramientas para asegurar el éxito financiero de tus
                        proyectos.</p>
                </div>
                {/*<div className="cnt_iframe">*/}
                {/*    <YouTubeVideoPlayer*/}
                {/*        videoId="T0ip3l274d4"*/}
                {/*        title="Video sobre inversiones en Dubái"*/}
                {/*    />*/}
                {/*</div>*/}
            </section>
            <section className='cnt_news'>
                <h2 className='news-title'>INVIERTE EN <br/> <span className="color">LOS EMIRATOS ÁRABES UNIDOS</span>
                </h2>
                <div className='cnt_news-cards'>
                    <div>
                        <div className='news-card background-option' loading="lazy">
                            <h3 className='news_card-title'>INVERSIONES INMOBILIARIAS EN DUBÁI</h3>
                            <p className='news_card-text'>Diego Carranza te da un análisis de  cuáles son <i className="color">los mejores proyectos para invertir</i> y tener los mejores rendimientos</p>
                            <Link to="/invertir-bienes-raises-dubai" className='button-contact'>
                                DESCUBRE COMO
                            </Link>
                        </div>
                        <div className='news-card background-option-4' loading="lazy">
                            <h3 className='news_card-title'>¿SABÍAS QUE PUEDES TENER TU PROPIA EMPRESA EN DUBAI EN MENOS DE UN MES?</h3>
                            <p className='news_card-text'>Te mostramos cómo aprovechar los <i className="color">beneficios que el gobierno otorga a los empresarios</i> extranjeros al abrir empresa aqui en Dubai. </p>
                            <Link to="/emigrar-a-dubai" className='button-contact'>
                                DESCUBRE COMO
                            </Link>
                        </div>
                    </div>
                    <div>
                        <div className='news-card background-option-2' loading="lazy">
                            <h3 className='news_card-title'>¿QUIERES INVERTIR EN CRIPTODIVISAS EN DUBAI?</h3>
                            <p className='news_card-text'>Convierte tus <i className="color">criptomonedas en propiedades</i> de lujo y obtén tu <i className="color">visa de residencia</i> al ser inversionista  de una propiedad en Dubai</p>
                            <Link to="/invertir-criptomonedas-dubai" className='button-contact'>
                                MIRA CÓMO AQUÍ
                            </Link>
                        </div>
                        <div className='news-card background-option-3' loading="lazy">
                            <h3 className='news_card-title'>OBTÉN TU RESIDENCIA EN DUBÁI DE FORMA RAPIDA Y SEGURA</h3>
                            <p className='news_card-text'>Con la asesoria de Diego Carranza el proceso de migrar y obtener tu visa de residencia en Dubai es más simple que nunca</p>
                            <Link to="/obtener-residencia-dubai" className='button-contact'>
                                MIRA CÓMO AQUÍ
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            <section className='cnt_podcast'>
                <h2 className='podcast-title'>EN NUESTRO <i className='color'>PODCAST</i> APRENDERAS <i className='color'>A INVERTIR
                    CON </i> DIEGO CARRANZA</h2>
                <div className='cnt_person'>
                    <div className='quotation'>
                        <img loading='lazy' src={quotation} alt='quotation' className='svg-icon-phone'/>
                    </div>
                    <div className='person'>
                        <p className='person-opinion'>"Al principio tenia mis dudas con el proceso, debido a que era
                            mucho el dinero que queria invertir, no estaba muy seguro de lo que podria pasar pero Diego
                            me dió la confianza y la seguridad para invertir. Desde el primer momento me brindo
                            espectativas reales y asesoramiento profesional. Aprendí muchisimo de este crack."</p>
                        <p className='person-name'> - Alejandro Fernandez</p>
                        <p className='person-work'><i>Experto en contaduría estatal</i></p>
                    </div>
                </div>
                <div className="carousel-container2">
                    <Slider {...settings}>
                        {data.map((item, index) => (
                            <div key={index} className={`card`}>
                                <img loading="lazy" src={item.image} alt={item.title} className="card-image"/>
                                <div className='cnt_card-text'>
                                    <h3 className="card-title">{item.title}</h3>
                                    <p className="card-text">{item.text}</p>
                                    <a href={item.link} className='button-red'>DESCUBRELO AQUÍ</a>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
                <a href='https://www.youtube.com/@InversionesDubai' className='button-youtube'>CONSEJOS DE INVERSIÓN</a>
            </section>
            <section className='cnt_success-people'>
                <h2 className='title-success-people'>¡CUALES SON LOS <i className="color">CASOS DE EXITO</i> DE DIEGO CARRANZA!</h2>
                <div className='cnt_cards-opinions'>
                    <div className='card-opinion'>
                        <img loading='lazy' src={quotationw} alt='quotation' className='quotation-opinion'/>
                        <h3 className='title-opinion'>¡FACTURÉ 500K EN MI PRIMER AÑO DE EMPRESA!</h3>
                        <p className='text-opinion'>"Llegué al sitio web de Diego con la esperanza de poder cambiar mi
                            forma de ganar dinero, buscando algo más estable, seguro y fijo.</p>
                        <p className='text-opinion'>Me guió y orientó a invertir en el mercado de Dubái, comenzando
                            desde las criptomonedas hasta montar una sede de mi negocio.</p>
                        <p className='text-opinion'>Ha sido espectacular el alcance que he tenido gracias a la ayuda que
                            me ha brindado."</p>
                        <p> - Lautaro Hernandez</p>
                    </div>
                    <div className='card-opinion'>
                        <img loading='lazy' src={quotationw} alt='quotation' className='quotation-opinion'/>
                        <h3 className='title-opinion'>¡PASE DE OBTENER GANANCIAS 4.5K A 45K MENSUALES!</h3>
                        <p className='text-opinion'>"Soy Asesor comercial para empresas medianas. Contacté con Diego
                            porque llegué a un punto de mi carrera en el cual no crecía profesionalmente. Llegué a él
                            gracias a su pagina web y no me arrepiento.</p>
                        <p className='text-opinion'>Fue una de las mejores experiencias que he podido tener. Me asesoró
                            tanto en imagen comercial como en personal, haciendo que empresas de Dubai se fijen en mi
                            perfil.</p>
                        <p className='text-opinion'>Lo recomiendo completamente."</p>
                        <p> - Andres Garcia</p>
                    </div>
                    <div className='card-opinion'>
                        <img loading='lazy' src={quotationw} alt='quotation' className='quotation-opinion'/>
                        <h3 className='title-opinion'>INCREIBLE LO QUE DIEGO LOGRA CON SU CONOCIMIENTO</h3>
                        <p className='text-opinion'>"Mi empresa estaba en busqueda de nuevas inversiones, analizando las
                            posibilidades que tenía, llegamos a la conclusión de que Dubái era un país potencial.</p>
                        <p className='text-opinion'>Contacté con Diego debido a que necesitaba posicionar mi merca en
                            ese mercado. Lo hizo perfectamente, me ha asesorado excelente. En menos de un año ya tenia
                            clientes, ventas, ganancias. INCREIBLE!!!</p>
                        <p className='text-opinion'>Felicidades para este crack!"</p>
                        <p> - Sara Layla</p>
                    </div>
                </div>
            </section>
        </main>
    );
}
