import React from "react";
import facebook from "../../assets/svg/facebook.svg"
import instagram from "../../assets/svg/instagram.svg"
import tiktok from "../../assets/svg/tiktok.svg"
import linkedin from "../../assets/svg/linkedin.svg"
import x from "../../assets/svg/x.svg"
import youtube from "../../assets/svg/youtube.svg"
import phone from "../../assets/svg/phone.svg"

import "./call.css"

export default function Call() {
    return (
        <div className="cnt_call">
            <div className="cnt_svg">
                <a href="https://www.facebook.com/profile.php?id=100063613425457" target="_blank" rel="noreferrer">
                    <img src={facebook} alt="facebook" className="svg-icons" />
                </a>
                <a href="https://www.instagram.com/inversionesdubaiuae/" target="_blank" rel="noreferrer">
                    <img src={instagram} alt="instagram" className="svg-icons" />
                </a>
                <a href="https://www.tiktok.com/@inversionesdubai" target="_blank" rel="noreferrer">
                    <img src={tiktok} alt="tiktok" className="svg-icons" />
                </a>
                <a href="https://www.linkedin.com/in/diego-javier-carranza-b526a8ba/" target="_blank" rel="noreferrer">
                    <img src={linkedin} alt="linkedin" className="svg-icons" />
                </a>
                <a href="https://www.youtube.com/@InversionesDubai" target="_blank" rel="noreferrer">
                    <img src={youtube} alt="youtube" className="svg-icons" />
                </a>
                <a href="https://www.youtube.com/@InversionesDubai" target="_blank" rel="noreferrer">
                    <img src={x} alt="x" className="svg-icons" />
                </a>
            </div>
            <div className="call_button">
                <img src={phone} alt="" className="svg-icon-phone" />
                <a href="https://wa.link/rdww5u" rel="noreferrer" target="_blank" >
                    AGENDA UNA REUNION
                </a>
            </div>
        </div>
    )
}