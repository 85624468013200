import React from "react";
import {Link} from "react-router-dom";

import {Helmet} from 'react-helmet-async';

import et from '../../assets/images/entrepeneur.webp'
import CalendlyPopupButton from "../calendly/calendly";

export default function Taxes() {
    return (
        <div className="cnt_advice">
            <Helmet>
                <title>Invierte en Dubai sin pagar impuestos - Inversiones Dubai</title>
                <meta name="description"
                      content="Si buscas invertir en Dubai ahorrando en Impuestos, Tasas arancelarias, etc... Diego Carranza te ayuda con la asesoria de Inversiones para evitar pagar más dinero."/>
                <link rel="canonical" href="https://inversionesdubai.com"/>
                <meta name="robots" content="index, follow"/>
                <link rel="preload"
                      href="https://res.cloudinary.com/dm9pjru06/image/upload/v1725581722/Dise%C3%B1o_sin_t%C3%ADtulo_6_cpux9w.webp"
                      as="image" type="image" crossorigin="anonymous"/>
            </Helmet>
            <div className="cnt_first-background">
                <h2 className="background-title size-title"><i className="color">INVIERTE AHORA EN</i> DUBAI</h2>
                <p className="background-title">Diego Carranza, reconocido inversionista, te ayuda a invertir en Dubai
                    de la mejor forma</p>
                <CalendlyPopupButton/>
            </div>
            <div className="cnt_advice-title">
                <h1 className="title-advice">INVIERTE <i className="color">EN DUBÁI</i> Y NO <i className="color">PAGAS
                    IMPUESTOS </i> CON MIS MÉTODOS</h1>
            </div>
            <div className="advice-card-information">
                <h2 className="advice-card-title color">¿Estás buscando asesoramiento para inversiones e impuestos en Dubai?</h2>
                <p className="advice-card-text"> ¿Te gustaría invertir sin pagar tasas arancelarias, impuestos a la
                    renta, etc? ¿Por qué no consideras Dubái como tu refugio fiscal? Dubái ofrece muchas ventajas
                    fiscales que pueden ayudarte a ahorrar dinero. Por ejemplo, no hay impuestos sobre la renta ni sobre
                    el patrimonio, lo que significa que puedes mantener más de tu dinero. Además, las autoridades de
                    Dubái han establecido un sistema financiero sólido y confiable, por lo que puedes estar seguro de
                    que tu dinero estará a salvo. Si quieres invertir sin pagar impuestos innecesarios, obtener
                    beneficios fiscales y además de eso, <Link className="links-decoration"
                                                               to="/obtener-residencia-dubai">residencia en Dubai</Link>;
                    no esperes más. Aquí te asesoro para poder invertir en Dubai y obtener la independencia económica
                    que deseas.</p>
                <h2 className="advice-card-title color">¿Cuáles son los Impuestos y otras ventajas de residir en
                    Dubai?</h2>
                <p className="advice-card-text">Emiratos Árabes Unidos es uno de los prominentes centros financieros del
                    golfo, conocido por sus altos rascacielos, su aeropuerto internacional y su nula tributación. Como
                    profesional, informo siempre a mis clientes con la normativa gubernamental de Emiratos Árabes Unidos
                    y siempre desde la perspectiva legal y formal.</p>
                <p className="advice-card-text">Desde mis victorias y clientes satisfechos te ayudaré con ejemplos
                    reales a como invertir en los Emiratos Árabes Unidos donde, sin duda, el destino más solicitado es
                    Dubái y sus «free trade zones» debido a sus normas diseñadas para atraer la inversión extranjera,
                    generar empresa e <i className="color">INVERTIR DE FORMA SEGURA</i>.</p>
                <p className="advice-card-text">Centenares de nómadas digitales, millonarios, deportistas e inversores
                    están estableciendo su <Link to="/obtener-residencia-dubai" className="links-decoration">residencia
                        en Dubái</Link> para ganar <Link to="/inversiones-impuestos-dubai" className="links-decoration">dinero
                        sin pagar impuestos</Link> y disfrutar de un ambiente internacional festivo y distendido. ¿Por
                    qué no tomarías este beneficio tú? Si puedes Invertir en Dubai, no pagar impuestos y además de eso
                    obtener beneficios fiscales.</p>
                <p className="advice-card-text">Dubái es un destino especialmente atractivo para gente joven con perfil
                    internacional, ya que, a diferencia de Andorra o Suiza; Dubái tiene una gran oferta de ocio y un
                    aeropuerto internacional, que conecta prácticamente con todos los lugares del mundo.</p>
                <p className="advice-card-text">Dubái se caracteriza por la ausencia total de impuestos directos y la
                    presencia de un impuesto indirecto limitado. Invierte en Dubai y sus alrededores para poner <Link
                        to="/invertir-bienes-raises-dubai" className="links-decoration">generar empresa</Link> y así
                    obtener la tan anhelada independencia económica.</p>
            </div>
            <div className="man-of-the-year">
                <h2>NOMINADO A EMPRENDEDOR DEL AÑO</h2>
                <div className="cnt_moty">
                    <div className="just-moty">
                        <img src={et} alt="diego" className="moty"/>
                    </div>
                    <div className="just-moty">
                        <p className="moty-text">DIEGO CARRANZA <br/><i className="color">EMPRENDEDOR DEL AÑO</i><br/>
                        </p>
                        <a href="https://www.instagram.com/p/C0HhnI0tn3c/" className='button-contact size'>
                            MIRALO AQUÍ
                        </a>
                    </div>
                </div>
            </div>
            <div className='news-card'>
                <h3 className='news_card-title'>INVIERTE EN DUBAI <i className="color">SIN PAGAR
                    IMPUESTOS</i> LEGALMENTE</h3>
                <p className='news_card-text'>Sé parte de las muchas personas que se suman diariamente a cambiar su vida
                    y empezar a vivir en una de las ciudades más ricas del mundo.</p>
                <a href="https://wa.link/rdww5u" rel="noreferrer" target="_blank" className='button-contact'>
                    AGENDA TU REUNION AQUÍ
                </a>
            </div>
        </div>
    )
}