import React from "react";
import { Link } from "react-router-dom";

import { Helmet } from 'react-helmet-async';

import et from '../../assets/images/entrepeneur.webp'
import CalendlyPopupButton from "../calendly/calendly";

export default function Cripto() {
    return (
        <div className="cnt_advice">
            <Helmet>
                <title>Invierte en Criptodivisas en Dubai - Inversiones Dubai</title>
                <meta name="description" content="Si quieres invertir tu dinero en criptomonedas y guardarlas en Dubai, diego carranza te asesora para invertir en las crypto con más auge del mercado" />
                <link rel="canonical" href="https://inversionesdubai.com" />
                <meta name="robots" content="index, follow" />
                <link rel="preload" href="https://res.cloudinary.com/dm9pjru06/image/upload/v1725581722/Dise%C3%B1o_sin_t%C3%ADtulo_6_cpux9w.webp" as="image" type="image" crossorigin="anonymous" />
            </Helmet>
            <div className="cnt_first-background">
                <h2 className="background-title size-title"><i className="color">INVIERTE AHORA EN</i> DUBAI</h2>
                <p className="background-title">Diego Carranza, reconocido inversionista, te ayuda a invertir en Dubai
                    de la mejor forma</p>
                <CalendlyPopupButton/>
            </div>
            <div className="cnt_advice-title">
                <h1 className="title-advice">INVIERTE <i className="color">EN CRIPTOMONEDAS</i> Y RESGUARDA <i className="color">
                    TUS INGRESOS </i> CON MIS MÉTODOS</h1>
            </div>
            <div className="advice-card-information">
                <h2 className="advice-card-title color">¿Cual es el proceso de invertir en cripto dubai?</h2>
                <p className="advice-card-text">Desde hace unos años, el mercado de criptomonedas está en auge y muchas
                    personas están interesadas en invertir en ellas. Sin embargo, el tema de la tributación en este ámbito es un poco complicado y muchas personas no saben por dónde empezar.</p>
                <p className="advice-card-text">Según la famosa revista estadounidense «Business Insider», en 2018 se vendieron 50 apartamentos de lujo en Dubái a cambio de Bitcoin, lo que marcó la primera transacción inmobiliaria para una criptomoneda en los Emiratos Árabes Unidos.</p>
                <p className="advice-card-text">Si usted está interesado en <i className="color">invertir en criptomonedas en Dubái</i>, o quiere saber <Link to="/inversiones-impuestos-dubai" className="links-decoration">cómo retirar criptomonedas sin pagar impuestos</Link>, pero no sabe por dónde empezar, yo le puedo ayudar.</p>
                <h2 className="advice-card-title color">¿Qué ventajas y desventajas hay en Dubai?</h2>
                <h3 className="advice-card-subtitle">¿Hay alguna tasa impositiva por sacar criptomonedas en Dubai?</h3>
                <p className="advice-card-text">Puede comprar criptomonedas en los Emiratos Árabes Unidos de la misma manera que compra Bitcoin. No tienes que pagar impuestos extra, solamente los feed de la empresa que realizan el cambio de criptomonedas a dinero fiat.</p>
                <h3 className="advice-card-subtitle">Si tengo muchas criptomonedas en mi país, ¿Me sale rentable sacarlas en Dubai?</h3>
                <p className="advice-card-text">Si tienes criptomonedas en tu país y quieres diversificar ese <Link to="/invertir-bienes-raises-dubai" className="links-decoration">dinero realizando inversiones</Link>, Dubái es una de las mejores opciones, ya que en Dubái puedes realizar inversiones inmobiliarias y crear empresas. A diferencia de los países latinos que tratan de penalizar el uso de criptomonedas o tratan de ponerles altos impuestos, Dubái está encaminando a  ser una ciudad donde todos puedan tener una billetera virtual y todos puedan tener sus activos digitales.</p>
                <h2 className="advice-card-title color">¿Puedo comprar una propiedad en Dubai con Bitcoin?</h2>
                <p className="advice-card-text">La propiedad inmobiliaria de Dubái aceptará transacciones con Bitcoin y otras criptomonedas, siempre que se sigan las leyes correspondientes. Para muchos inversores, Dubái y el resto de los Emiratos Árabes Unidos son actualmente la criptociudad más popular, así como una de las opciones más populares para los inversores que buscan <Link to="/invertir-bienes-raises-dubai" className="links-decoration">comprar bienes raíces en el país</Link>.</p>
                <h3 className="advice-card-subtitle">¿Se puede comprar una propiedad con Bitcoin en Dubai?</h3>
                <p className="advice-card-text">Sí; Es posible comprar una propiedad utilizando las criptomonedas «Bitcoin» en Dubái, pero no todos los vendedores aceptarían una oferta de moneda digital y, si lo hacen, es posible que solo acepten tipos de criptomonedas conocidas como Bitcoin o Ethereum.</p>
                <h3 className="advice-card-subtitle">Compre y venda bienes raíces en Dubai con criptomoneda Bitcoin</h3>
                <p className="advice-card-text">Hace unos años, era inimaginable comprar un terreno con una moneda digital unificada, pero hoy en día, es la tarea más fácil de hacer. Como todo el mundo está cambiando su paradigma a digital, no es una sorpresa que la moneda también se digitalice. Hoy en día, los avances tecnológicos le permiten <Link to="/invertir-bienes-raises-dubai" className="links-decoration">comprar bienes raíces</Link> con criptomonedas donde los métodos son más seguros que los medios tradicionales para comprar una propiedad.</p>
                <p className="advice-card-text">Te ofrezco un círculo completo de servicios en compra y venta de propiedades en Dubái con criptomonedas. Desde casas, palacios,  hasta apartamentos. Con la creciente necesidad de cambiar a medios de vida digitales, me enorgullezco de presentar el servicio para comprar y vender propiedades en Dubái.</p>
                <h3 className="advice-card-subtitle">¿Cuál es el proceso de transacción de la criptomoneda?</h3>
                <p className="advice-card-text">El proceso de compra en Emiratos Árabes Unidos es bastante sencillo. Primero, póngase en contacto conmigo. para encontrar la propiedad que más le guste. Luego confirme la compra y transfiera el dinero en criptomonedas. Después de eso, yo me encargaré del resto, la gestión y la compra del inmueble. Eso es todo, la propiedad está comprada.</p>
                <h2 className="advice-card-title color">¿A partir de cuantos € o $ me puede convenir ir a Dubai para convertir mis criptomonedas en dinero Fiat?</h2>
                <p className="advice-card-text">Usualmente, los latinos prefieren realizar las transferencias en criptomonedas y convertirlas en dinero fiat en Dubái. Para la cantidad no hay límite y las comisiones suelen ser muy bajas para este tipo de transacción. </p>
                <p className="advice-card-text">Por otro lado, usted puede comprar propiedades desde 140 mil USD,  o puede entrar a formas de pago en dólares sin interés, con tan solo abonar el 10 % del valor del proyecto (en caso de ser estudios puede rondar los 15 mil USD), lo otro que tiene que tener en cuenta, es que ya no es necesario viajar a Dubái para poder comprar una propiedad.</p>
            </div>
            <div className="man-of-the-year">
                <h2>NOMINADO A EMPRENDEDOR DEL AÑO</h2>
                <div className="cnt_moty">
                    <div className="just-moty">
                        <img src={et} alt="diego" className="moty" />
                    </div>
                    <div className="just-moty">
                        <p className="moty-text">Diego carranza <br /><i className="color">Emprendedor del año</i><br /></p>
                        <a href="https://www.instagram.com/reel/C0HhnI0tn3c/?igsh=ZjRoOXJhZDZ2c2lj" rel="noreferrer" target="_blank" className='button-contact color size'>
                            MIRALO AQUÍ
                        </a>
                    </div>
                </div>
            </div>
            <div className='news-card'>
                <h3 className='news_card-title'>INVIERTE EN <i className="color">CRIPTO AHORA</i> Y NO GUARDES DINERO "DEBAJO DEL COLCHON"</h3>
                <p className='news_card-text'>Sé parte de las muchas personas que se suman diariamente a cambiar su vida y empezar a vivir en una de las ciudades más ricas del mundo.</p>
                <a href="https://wa.link/rdww5u" className='button-contact'>
                    AGENDA TU REUNION AQUÍ
                </a>
            </div>
        </div>
    )
}