import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

import logo from '../../assets/images/img.png'

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState({});
  const [isMobile, setIsMobile] = useState(false);
  const menuRef = useRef(null);

  // Función para detectar si es dispositivo móvil
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => {
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []);

  const toggleSubMenu = (index) => {
    if (isMobile) {
      setIsSubMenuOpen((prev) => ({
        ...prev,
        [index]: !prev[index],
      }));
    }
  };

  const closeMenu = () => {
    setIsOpen(false);
    setIsSubMenuOpen({});
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      closeMenu();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <nav className="navbar">
      <h3 className="nav-title">
        <Link to="/">
          <img src={logo} alt="logo" className="logo"/>
        </Link>
      </h3>
      <div ref={menuRef} className={`menu-container ${isOpen ? 'open' : ''}`}>
        <div className="hamburger" onClick={() => setIsOpen(!isOpen)}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
        <ul className={`menu ${isOpen ? 'open' : ''}`}>
          <li className="cnt_items-menu-sub">
            <div 
              className="menu-item" 
              onClick={() => toggleSubMenu(1)}
            >
              <p className="link-label">ACERCA DE</p>
              <span className={`arrow ${isSubMenuOpen[1] ? 'open' : ''}`}></span>
            </div>
            <ul className={`sub-menu ${isSubMenuOpen[1] ? 'open' : ''}`}>
              <li className='item-menu-sub'><Link to="/quien-es-inversiones-dubai" onClick={closeMenu}>DIEGO CARRANZA</Link></li>
              <li className='item-menu-sub'><Link to="/trabajar-con-inversiones-dubai" onClick={closeMenu}>PUBLICIDAD Y CONTRATACIONES</Link></li>
            </ul>
          </li>
          <li className="cnt_items-menu-sub">
            <div 
              className="menu-item" 
              onClick={() => toggleSubMenu(2)}
            >
              <p className="link-label">SERVICIOS</p>
              <span className={`arrow ${isSubMenuOpen[2] ? 'open' : ''}`}></span>
            </div>
            <ul className={`sub-menu ${isSubMenuOpen[2] ? 'open' : ''}`}>
              <li className='item-menu-sub'><Link to='/inversiones-impuestos-dubai' onClick={closeMenu}>INVERSIONES E IMPUESTOS</Link></li>
              <li className='item-menu-sub'><Link to="/obtener-residencia-dubai" onClick={closeMenu}>OBTENER RESIDENCIA</Link></li>
              <li className='item-menu-sub'><Link to='/invertir-bienes-raises-dubai' onClick={closeMenu}>INVERSIONES EN BIENES RAICES</Link></li>
              <li className='item-menu-sub'><Link to="/emigrar-a-dubai" onClick={closeMenu}>EMIGRAR A DUBAI</Link></li>
              <li className='item-menu-sub'><Link to="/invertir-criptomonedas-dubai" onClick={closeMenu}>INVERTIR EN CRIPTO</Link></li>
            </ul>
          </li>
          <li className="cnt_items-menu-sub">
            <div 
              className="menu-item" 
              onClick={() => toggleSubMenu(3)}
            >
              <p className="link-label">PODCAST</p>
              <span className={`arrow ${isSubMenuOpen[3] ? 'open' : ''}`}></span>
            </div>
            <ul className={`sub-menu ${isSubMenuOpen[3] ? 'open' : ''}`}>
              <li className='item-menu-sub'><Link to="/podcast-inversiones-dubai" onClick={closeMenu}>CONTENIDO RECIENTE</Link></li>
              <li className='item-menu-sub'><Link to="/podcast-invitados-dubai" onClick={closeMenu}>INVITADOS ESPECIALES</Link></li>
            </ul>
          </li>
          <li className="cnt_items-menu-sub">
            <div 
              className="menu-item" 
              onClick={() => toggleSubMenu(4)}
            >
              <p className="link-label">ENTRENA CONMIGO</p>
              <span className={`arrow ${isSubMenuOpen[4] ? 'open' : ''}`}></span>
            </div>
            <ul className={`sub-menu ${isSubMenuOpen[4] ? 'open' : ''}`}>
              <li className='item-menu-sub'><Link to="/aprende-sobre-inversiones-dubai" onClick={closeMenu}>MASTERCLASS</Link></li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;