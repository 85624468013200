import React from 'react';
import { PopupButton } from 'react-calendly';

const CalendlyPopupButton = () => {
    return (
        <div>
                <PopupButton
                    className='button-contact'
                    url="https://calendly.com/diego-carranza" 
                    rootElement={document.getElementById('root')}
                    text="AGENDAR ASESORIA"
                />
        </div>
    );
};

export default CalendlyPopupButton;
